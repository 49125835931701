/* ////////////////////    FONT FAMILY / GENERAL    ////////////////////////// */
html {
  scroll-behavior: smooth;
}

.inter-normal {
  font-family: 'Inter';
  font-weight: 500;
}

.inter-medium {
  font-family: 'Inter';
  font-weight: 600;
}

.inter-bold {
  font-family: 'Inter';
  font-weight: bold;
}

.inter-extrabold {
  font-family: 'Inter';
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/inter/Inter-Regular.ttf") format("ttf"),
    url("/public/fonts/inter/Inter-Regular.otf") format("otf"),
    url("/public/fonts/inter/Inter-Regular.woff") format("woff"),
    url("/public/fonts/inter/Inter-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("/public/fonts/inter/Inter-Medium.ttf") format("ttf"),
    url("/public/fonts/inter/Inter-Medium.otf") format("otf"),
    url("/public/fonts/inter/Inter-Medium.woff") format("woff"),
    url("/public/fonts/inter/Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  src: url("/public/fonts/inter/Inter-Bold.ttf") format("ttf"),
    url("/public/fonts/inter/Inter-Bold.otf") format("otf"),
    url("/public/fonts/inter/Inter-Bold.woff") format("woff"),
    url("/public/fonts/inter/Inter-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("/public/fonts/inter/Inter-ExtraBold.ttf") format("ttf"),
    url("/public/fonts/inter/Inter-ExtraBold.otf") format("otf"),
    url("/public/fonts/inter/Inter-ExtraBold.woff") format("woff"),
    url("/public/fonts/inter/Inter-ExtraBold.woff2") format("woff2");
}


.poppins-normal {
  font-family: 'Poppins';
  font-weight: normal;
}

.poppins-semibold {
  font-family: 'Poppins';
  font-weight: 600;
}

.poppins-bold {
  font-family: 'Poppins';
  font-weight: bold;
}

* {
  /* font-family: 'Inter'; */
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FDFEFF;
  background-color: #FDFEFF;
}


p {
  line-height: 1.0;
  margin: 0;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100% !important;
}


.txt_truncate2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  /* min-height: 2.86em; */
}

.txt_truncate1 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.txt-truncate {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


/* ////////////////////    COLOR    ////////////////////////// */
.cl-dark {
  color: #232325
}

.cl-theme {
  color: #469DFF;
}

.cl-lgray {
  color: #464646;
}

.cl-secondary {
  color: #FFC943;
}

.cl-text {
  color: #1B1B1B;
}

.bg-theme {
  background: #469DFF;
}

.bg-secondary {
  background: #FFC943;
}

.bg-base {
  background: #FDFEFF;
}

.gradient-blue {
  background: -o-linear-gradient(236deg, #46CEFF 15.14%, #469DFF 84.28%);
  background: linear-gradient(214deg, #46CEFF 15.14%, #469DFF 84.28%);
}

.gradient-yellow {
  background: -o-linear-gradient(236deg, #FFE49D 15.14%, #FFC943 84.28%);
  background: linear-gradient(214deg, #FFE49D 15.14%, #FFC943 84.28%);
}

.gradient-green {
  background: -o-linear-gradient(236deg, #BEFA8F 15.14%, #3FC973 84.28%);
  background: linear-gradient(214deg, #BEFA8F 15.14%, #3FC973 84.28%);
}

/* ////////////////////    SHAPE    ////////////////////////// */

.cut-edge-bottom {
  background:
    -o-radial-gradient(bottom left, circle 15px, #0000 98%, #fff) bottom left,
    -o-radial-gradient(bottom right, circle 15px, #0000 98%, #fff) bottom right;
  background:
    -o-linear-gradient(30deg, transparent 1rem, #fff 0) bottom left,
    -o-linear-gradient(150deg, transparent 1rem, #fff 0) bottom right;
  background:
    linear-gradient(60deg, transparent 1rem, #fff 0) bottom left,
    linear-gradient(-60deg, transparent 1rem, #fff 0) bottom right;
  background-size: 51% 100%;
  background-repeat: no-repeat;
}

/* ////////////////////    DIVIDER    ////////////////////////// */
.divider-bar {
  width: 80px;
  height: 8px;
}

.divider-dark {
  border-radius: 48px;
  background: #05122D;
  height: 4px;
  width: 100%;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(153, 249, 255, 0.14), 0px 1px 2px 0px #000 inset;
  box-shadow: 0px 1px 2px 0px rgba(153, 249, 255, 0.14), 0px 1px 2px 0px #000 inset;
}

.divider-white {
  border-radius: 48px;
  background: #ffffff12;
  height: 4px;
  width: 100%;
  -webkit-box-shadow: 0px 1px 2px 0px rgb(153 249 255 / 15%), 0px 2px 2px 0px #00000087 inset;
  box-shadow: 0px 1px 2px 0px rgb(153 249 255 / 15%), 0px 2px 2px 0px #00000087 inset;
}

/* ////////////////////    FONT SIZE    ////////////////////////// */
.fs-header {
  font-size: 1.8rem;
}

.fs-subheader {
  font-size: 1.3rem;
}

.fs-content {
  font-size: 1.1rem;
  line-height: 1.2;
  font-family: Arial, Helvetica, sans-serif;
}

.fs-subcontent {
  font-size: 1rem;
  line-height: 1.2;
  font-family: Arial, Helvetica, sans-serif;
}

.fs-title {
  font-weight: bold;
  font-size: 1.6rem;
}

.fs-collapse-title {
  font-size: 18px;
}

.fs-title-docs{
  font-size: 1.3rem;
  padding-bottom: 8px;
}


/* ///////////////  Border Radius   ////////////////// */
.bor5 {
  border-radius: 10px;
}

.bor10 {
  border-radius: 10px;
}

.bor15 {
  border-radius: 15px;
}

.bor20 {
  border-radius: 20px;
}

.bor25 {
  border-radius: 25px;
}

.bor30 {
  border-radius: 30px;
}

.bor35 {
  border-radius: 35px;
}

.bor40 {
  border-radius: 40px;
}

.bor45 {
  border-radius: 45px;
}

.bor48 {
  border-radius: 48px;
}

.bor50 {
  border-radius: 50px;
}

/* ///////////////  BOX SHADOW   ////////////////// */
.shadow-out {
  -webkit-box-shadow: 0 0 15px #dfe8fa;
  box-shadow: 0 0 15px #dfe8fa;
}

/* ///////////////  BUTTON   ////////////////// */
.btn-theme {
  background: #469DFF;
  border-radius: 10px;
  padding: 12px 15px;
  color: #fff;
  text-align: center;
  min-width: 150px;
  width: fit-content;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.btn-theme:hover {
  background: #5db4fb;
}

/* ///////////  EMPTY STATE  ////////////////// */
.emptystate-title {
  font-size: 17px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #dfdfdf;
  font-weight: bold;
  padding-top: 13px;
}

.emptystate-desc {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #dfdfdf;
  padding-top: 7px;
}

.emptystate-img {
  width: 120px;
  -webkit-filter: drop-shadow(3px 0px 2px #0002);
  filter: drop-shadow(3px 0px 2px #0002)
}

#product_images img {
  max-width: 100% !important;
}

/* ///////////  SWIPER  ////////////////// */

.swiper-button-prev,
.swiper-button-next,
.swiper-button-prev:after,
.swiper-button-next:after {
  color: white !important;
  font-size: medium !important;
  width: 6px;
  height: 10px;
  /* margin-top: -15px; */
  font-size: 10px;
  top: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 20px
}

.swiper-button-prev {
  margin-left: -10px;
}

.swiper-button-next {
  margin-right: -10px;
}

/* ///////////  AWSSLD  ////////////////// */
.awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.8);
}

.awssld__content {
  background-color: transparent;
}

.shop .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.215);
}


.awssld__controls button .awssld__controls__arrow-left,
.awssld__controls button .awssld__controls__arrow-right {
  opacity: 1;
}

.awssld {
  --organic-arrow-color: #fff;
}


.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.orgchart-container {
  border: 2px dashed #d9d9d9;
  margin: 10px 0 0;
}

.slick-prev:before,
.slick-next:before {
  color: transparent;
}

.image-gallery-content.fullscreen {
  top: 20vh;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}

/* ////////////////////    marquee    ////////////////////////// */
.marquee {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fdc432;
  color: #333;
  border-bottom: 1px solid #e4e4e4;
  -webkit-box-shadow: inset -1px 3px 6px 0 #00000070;
  box-shadow: inset -1px 3px 6px 0 #00000070;
  border-radius: 0 0 15px 15px;
}

.marquee .marquee_content {
  /* position: absolute; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  line-height: 50px;
  -webkit-transform: translateX(450px);
  -ms-transform: translateX(450px);
  transform: translateX(450px);
  -webkit-animation: scroll-left 100s linear infinite;
  animation: scroll-left 100s linear infinite;
}

.marquee_content p {
  text-align: center;
  padding-right: 15px;
  padding-top: 1px;
  margin: 0;
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(450px);
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -webkit-transform: translateX(450px);
    transform: translateX(450px);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* ///////////////   PUBLIC PAGE or DESIGN    ///////////////// */
.coin-usdt {
  width: 100px;
  -webkit-transform: rotate(12.551deg);
  -ms-transform: rotate(12.551deg);
  transform: rotate(12.551deg);
  right: 20px;
  bottom: -50px
}

.coin-bitcoin {
  width: 110px;
  -webkit-transform: rotate(-17.077deg);
  -ms-transform: rotate(-17.077deg);
  transform: rotate(-17.077deg);
  left: 20px;
  top: -55px
}

.contact-icon {
  border-radius: 50px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(89, 116, 168, 0.19);
  box-shadow: 0px 0px 30px 0px rgba(89, 116, 168, 0.19);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  min-width: 55px;
  width: 55px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 35px;
}

/* ///////////////   DOCS PAGE    ///////////////// */
.p-content{
  padding: 45px;
}

.docs-list p{
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.docs-list p:hover{
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.fs-content li{
  font-weight: 500;
    font-size: 1rem;
    padding-bottom: 15px;
    word-break: break-all;
}

.fs-content span{
  font-family: 'Inter';
  font-weight: 900;
}

/* ///////////////   TABLE - EXAMPLE    ///////////////// */
.table-box{
  background: #FDFEFF;
    -webkit-box-shadow: 0 0 15px #dfe8fa;
    box-shadow: 0 0 15px #dfe8fa;
    padding: 15px;
    border-radius: 15px;
    margin-top: 5px;
  }

.table-title{
  width: 250px;
  min-width: 150px;
  padding: 10px 15px;
  border-right: 1px solid #e4e4e4;
}
.table-subtitle{
  width: 200px;
  min-width: 200px;
  padding: 10px 15px;
}
.table-content{
  width: 100%;
  word-break: break-all;
  padding: 10px 15px;
}
.table-subcontent{
  width: 100%;
  padding: 10px 15px;
}
.table-box .flex-str{
  border-bottom: 1px solid #e4e4e4;
}
.table-content .flex-str, .table-box .flex-str:last-child {
  border-bottom: none;
}

/* ///////////////   CODE STYLE    ///////////////// */
.code-lh p{
  line-height: 1.5;
}
.code-tab{
  padding-left: 15px;
}

.code-red{
  color: #e0727f;
  font-weight: 500;
}

.code-green{
  color: #82ba36;
  font-weight: 500;
}

/* ///////////////   ANIMATION    ///////////////// */

.mail-stamp-round, .mail-stamp-line{
  opacity: 0;
  animation-name: stamp;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.mail-stamp-line{
  animation-delay: 0.3s;
}

.mail-sent{
  animation-name: mailsent;
  animation-delay: 1s;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.mailbox{
  opacity: 0;
  animation-name: mailbox;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.mail-received{
  opacity: 0;
  animation-name: mailreceived;
  animation-delay: 3s;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}




@keyframes stamp {
  0%   {opacity: 0; transform: scale(3)}
  100%   {opacity: 1; transform: scale(1)}
}

@keyframes mailsent {
  0% {
    transform: translateY(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes mailreceived {
  0% {
    transform: translateY(0) scale(3);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    transform: translateY(95px);
    opacity: 1;
  }

  100% {
    transform: translateY(125px) scale(1);
    opacity: 0;
  }
}

@keyframes mailbox {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* ///////////////   HOVER EFFECT    ///////////////// */

.translateY {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateY:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateX {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateX:hover {
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* ////////////////////    MEDIA SCREEN    ////////////////////////// */
@media only screen and (max-width: 600px) {

    /* /////////  landing  ///////// */
  .flex-col-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .txt-center-mobile {
    text-align: center;
  }

  .center-mobile {
    margin: 0 auto;
  }

  .coin-usdt {
    width: 80px;
    right: 20px;
    bottom: -40px
  }

  .coin-bitcoin {
    width: 70px;
    left: 20px;
    top: -40px
  }

  /* /////////  docs  ///////// */

  .p-content{
    padding: 24px;
  }

  /* /////////  table  ///////// */
  .table-subtitle, .table-subcontent{
    padding: 2px 0;
  }

  .table-title{
    padding: 10px 15px 0;
  }
  .table-content{
    padding: 5px 15px 15px;
  }

  .table-title, .table-subtitle{
    width: 100%;
    border-right: none;
    font-weight: bold;
  }

  .table-content, .table-subcontent{
    width: 100%;
  }

  .table-content .flex-str {
    padding:8px 0;
    border-bottom: 1px solid #e4e4e4;
  }

  .table-content .flex-str, .table-box .flex-str{
    flex-direction: column;
  }

}

@media only screen and (min-width: 601px) {}
@media only screen and (max-width: 900px) {
  .txt-center-mddown {
    text-align: center;
  }
}
@media (max-width: 959.95px) {}

.codesnip {
  width: 100%;
  /* height: 700px; */
  overflow: auto;
  /* margin-left: 100px; */
  margin-top: 10px;
  border-radius: 17px;
}

.language-php {
  word-break: break-word !important;
}

p {
  word-break: break-word;
}

.content-hover:hover {
  /* font-weight: 600; */
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}